<template>
  <v-sheet elevation="3" class="my-7 py-10 px-5">
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <h5 class="py-3 text-h4 font-weight-bold text-medium-emphasis">Why Us?</h5>
      </v-col>
      <v-col cols="12">
        <v-row justify="center" align="center">
          <v-col
            v-for="(item, index) in items"
            :key="index"
            cols="12"
            md="4"
            class="d-flex justify-center"
          >
            <v-card elevation="7" class="pa-5 block">
              <v-card-title class="font-weight-bold ">
                {{ item.title }}
              </v-card-title>
              <v-card-text class="text-body-1 text-medium-emphasis">{{ item.text }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "ChooseUs",
  data() {
    return {
      items: [
        {
          text: "We use problem-solving techniques and varied approaches gets you results. What results do you want? Let us get you your desired outcome.",
          title: "Problem Solving",
        },
        {
          title: "Prescriptive Diagnosis",
          text: "Why treat what is undiagnosed? Here at Lifeline we diagnose your challenges and implement the right solutions to address them.",
        },
        {
          title: "Partnerships",
          text: "We Partner with diverse educational professionals to deliver quality. We bring in professionals of every kind to help you achieve your desire.",
        },
        {
          title: "Customized Solutions",
          text: "Choose from our offers ~ we have a package for every need and budget.",
        },
        {
          title: "Guidance",
          text: "We provide your ward the right assistance and guidance to develop.",
        },
        {
          title: "Success Stories",
          text: "We have helped hundreds of students and counting. Become part of our success.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.choose-title {
  font-weight: bold;
  font-size: 1.5rem;
}
</style>
